<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import { removeToken } from '@/Helpers/auth';
import { onMounted } from 'vue';
import AppHead from '../Components/AppHead.vue';

onMounted(() => {
  // Remove any API tokens relating to a logged-in user. This allows
  // a new token to be generated once we reach the dashboard.
  removeToken();
});
</script>

<template>
  <AppHead />

  <div
    class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-4 bg-slate-100 pb-10"
  >
    <div>
      <a href="https://zerokey.tech/">
        <ApplicationLogo class="h-12 fill-current text-slate-500" />
      </a>
    </div>

    <div
      class="w-full sm:max-w-md mt-6 p-6 lg:p-8 bg-white overflow-hidden sm:rounded-xl ring-1 ring-gray-500/10 shadow sm:shadow-md"
    >
      <slot />
    </div>
  </div>
</template>
